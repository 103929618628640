import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import styles from './styles.cssm';

const HeroContent = ({
  title,
  body,
  heroImageUrl,
  heroImageWidth,
  heroImageHeight,
  mobile,
  desktop,
}) => {
  const isMobileViewport = useSelector(isMobileViewportSelector);
  let cta1 = desktop.desktopCta1;
  let cta2 = desktop.desktopCta2;

  if (isMobileViewport) {
    cta1 = mobile.mobileCta1;
    cta2 = mobile.mobileCta2;
  }

  return (
    <ContentColumn
      className={`px0 ${styles.bgWhiteWarm}`}
      innerClassName="md-flex"
      size="xl"
    >
      <div className="md-size6of12 flex justify-center items-center py5 lg-py4 text-center lg-text-left">
        <ContentColumn>
          {title && (
            <Heading
              className={`text-xxl text-semibold ${styles.colorGreenSlate} ${styles.letterSpacingNormal}`}
              tagName="h2"
            >
              {title}
            </Heading>
          )}
          {body && (
            <div
              className={`pt3 lg-pt4 text-sm lg-text-md ${styles.colorGreenSlate}`}
            >
              {body}
            </div>
          )}
          {(cta1 || cta2) && (
            <div className="pt5">
              {cta1 && <Button {...cta1} />}
              {cta2 && (
                <div className="mt3 text-xs lg-text-sm color-textBlack">
                  <Button
                    className="color-textBlack"
                    visualStyle="textLink"
                    {...cta2}
                  />
                </div>
              )}
            </div>
          )}
        </ContentColumn>
      </div>
      <div className="md-size6of12 flex justify-center items-center">
        {heroImageUrl && (
          <ImageLoader
            alt=""
            height={heroImageHeight}
            includeFallback={{
              enabled: true,
            }}
            lazyLoad={{
              enabled: false,
            }}
            role="presentation"
            src={heroImageUrl}
            width={heroImageWidth}
            wrapInFlex={{
              enabled: true,
              width: heroImageWidth,
              height: heroImageHeight,
              flexEmbedWrapperClassName: 'width-100',
              constrainWidth: true,
            }}
          />
        )}
      </div>
    </ContentColumn>
  );
};

export default HeroContent;
