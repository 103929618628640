import { push } from 'redux-first-history';

import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

import {
  REDEEM_PROMO,
  REDEEM_PROMO_ERROR,
  REDEEM_PROMO_SUCCESS,
  FETCH_PARTNERSHIP_AUTH,
  FETCH_PARTNERSHIP_AUTH_SUCCESS,
  FETCH_PARTNERSHIP_AUTH_ERROR,
  UNLINK_PARTNERSHIP,
  UNLINK_PARTNERSHIP_SUCCESS,
  UNLINK_PARTNERSHIP_ERROR,
} from './actions';

import { REDEEM_PROMO_CODE } from '../../universal/gql-operation-ids';

export const redeemPromo =
  ({ promoCodeString }) =>
  (dispatch, getState, { gqlFetch }) => {
    const mutation = {
      operationId: REDEEM_PROMO_CODE,
      destinationType: API_PROXY_V1,
      variables: {
        promoCode: promoCodeString,
      },
    };

    dispatch({ type: REDEEM_PROMO });
    return gqlFetch(mutation)
      .then((data) => {
        dispatch({ type: REDEEM_PROMO_SUCCESS, payload: data });
      })
      .catch((error) => {
        dispatch({ type: REDEEM_PROMO_ERROR, error });
      });
  };

export const partnershipAuthentication = () => {
  return (dispatch, getState, { apiFetch }) => {
    const marriottAuthUrl = `/apiproxy/v1/rewards/partnerships/marriottbonvoy/authenticate`;
    const fetchOptions = {
      method: 'GET',
    };
    dispatch({ type: FETCH_PARTNERSHIP_AUTH });

    return apiFetch(marriottAuthUrl, fetchOptions)
      .then((response) => {
        const payload = { ...response };
        dispatch({ type: FETCH_PARTNERSHIP_AUTH_SUCCESS, payload });
        return payload;
      })
      .catch((error) => {
        dispatch({ type: FETCH_PARTNERSHIP_AUTH_ERROR, error });
        window.location.href = '/rewards/partnerships/marriottbonvoy/error';
        throw error;
      });
  };
};

export const partnershipUnlink = (closeModal) => {
  return (dispatch, getState, { apiFetch }) => {
    const MARRIOTT_UNLINK_SUCCESS_PAGE =
      '/rewards/partnerships/marriottbonvoy/unlinked';
    const MARRIOTT_NEVER_LINKED_PAGE =
      '/rewards/partnerships/marriottbonvoy/not-linked';
    const MARRIOTT_LINK_ERROR_PAGE =
      '/rewards/partnerships/marriottbonvoy/error';
    const marriottUnlinkUrl = `/apiproxy/v1/rewards/partnerships/marriottbonvoy/unlink`;
    const fetchOptions = {
      method: 'GET',
    };
    dispatch({ type: UNLINK_PARTNERSHIP });

    return apiFetch(marriottUnlinkUrl, fetchOptions)
      .then(() => {
        dispatch({ type: UNLINK_PARTNERSHIP_SUCCESS });
        closeModal();
        dispatch(push(MARRIOTT_UNLINK_SUCCESS_PAGE));
      })
      .catch((error) => {
        dispatch({ type: UNLINK_PARTNERSHIP_ERROR, error });
        closeModal();
        if (error.data?.error?.code === '8001034') {
          return dispatch(push(MARRIOTT_NEVER_LINKED_PAGE));
        }
        dispatch(push(MARRIOTT_LINK_ERROR_PAGE));
      });
  };
};
