import React from 'react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import messages from './messages';
import Benefits from './benefits';
import GettingStarted from './getting-started';
import Hero from './hero';
import RewardsBanner from './rewards-banner';
import RewardTiers from './reward-tiers';
import LoyaltyPartnerships from './loyalty-partnerships';
import WaysToPayAndCollectStars from './ways-to-pay-and-collect-stars';
import Questions from './questions';
import Terms from './terms';

const RewardsPage = ({ intl: { formatMessage } }) => (
  <React.Fragment>
    <Helmet>
      <title>{formatMessage(messages.title)}</title>
      <meta
        content={formatMessage(messages.rewardsDescription)}
        property="description"
      />
    </Helmet>
    <RewardsBanner />
    <Hero />
    <div className="py5 md-py9">
      <GettingStarted />
    </div>
    <div className="py5 md-py9">
      {/* "Get your favorites for free" */}
      <RewardTiers />
    </div>
    <div className="py5 md-py9">
      {/* "Endless Extras" */}
      <Benefits />
    </div>
    <div className="pt5 md-pt9">
      {/* "Cash or card, you earn Stars" */}
      <WaysToPayAndCollectStars />
    </div>
    <div className="mb5">
      <LoyaltyPartnerships />
    </div>
    <div className="py5 md-py9">
      <Questions />
    </div>
    <div className="pt5 mb7">
      <Terms />
    </div>
  </React.Fragment>
);

export default injectIntl(RewardsPage);
