import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'rewards.hero.header',
    description: 'Promotional heading text',
    defaultMessage: 'It’s a great day for {newLine} free coffee',
  },
  body: {
    id: 'rewards.hero.body',
    description: 'Promotional text',
    defaultMessage:
      'Sign up and start enjoying the perks of Starbucks® Rewards.',
  },
  joinNow: {
    id: 'rewards.hero.joinNow',
    description: 'Join Starbucks Rewards program now',
    defaultMessage: 'Join now',
  },
  mobileCta: {
    id: 'rewards.hero.mobileCta2',
    description: 'Button text to indicate option to join Rewards on the web',
    defaultMessage: 'Or join online',
  },
  desktopCta: {
    id: 'rewards.hero.desktopCta2',
    description: 'Button text to indicate option to join Rewards on the app',
    defaultMessage: 'with the app',
  },
  forTheBestExperience: {
    id: 'rewards.hero.forTheBestExperience',
    description:
      'Helper text for the button to join the rewards app for the best experience',
    defaultMessage: "It's even better {joinLink}.",
  },
});

export default messages;
