import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import HeroContent from './content';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import {
  appDownloadLinkSelector,
  mobileAppsLandingPageUrlSelector,
} from '../../../state/selectors';

import messages from './messages';
import sharedMessages from '../messages';
import styles from './styles.cssm';

const RewardsHero = () => {
  const { formatMessage } = useIntl();
  const appDownloadLink = useSelector(appDownloadLinkSelector);
  const imageBaseUrl = useSelector(configImagesUrlSelector);
  const mobileAppsLandingPageUrl = useSelector(
    mobileAppsLandingPageUrlSelector
  );

  const mobileProps = {
    mobileCta1: {
      href: appDownloadLink,
      children: formatMessage(sharedMessages.joinInAppCTA),
      visualStyle: 'positive',
    },
    mobileCta2: {
      href: '/account/create',
      children: formatMessage(messages.mobileCta),
    },
  };

  const desktopProps = {
    desktopCta1: {
      href: '/account/create',
      children: formatMessage(messages.joinNow),
      visualStyle: 'positive',
    },
    desktopCta2: {
      tagName: 'span',
      className: 'text-noUnderline',
      children: formatMessage(messages.forTheBestExperience, {
        joinLink: (
          <a className="color-textBlack" href={mobileAppsLandingPageUrl}>
            {formatMessage(messages.desktopCta)}
          </a>
        ),
      }),
    },
  };

  return (
    <div className={styles.bgWhiteWarm}>
      <HeroContent
        body={<p>{formatMessage(messages.body)}</p>}
        desktop={{ ...desktopProps }}
        heroImageHeight={461}
        heroImageUrl={`${imageBaseUrl}/rewards/hero/rewards-hero.png`}
        heroImageWidth={720}
        mobile={{ ...mobileProps }}
        title={formatMessage(messages.header, { newLine: <br /> })}
      />
    </div>
  );
};

export default RewardsHero;
